import { render, staticRenderFns } from "./App.vue?vue&type=template&id=2f65e0d0"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QLayout,QHeader,QToolbar,QBtn,QToolbarTitle,QDrawer,QPageContainer} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QLayout,QHeader,QToolbar,QBtn,QToolbarTitle,QDrawer,QPageContainer})
