


import { AppFullscreen } from 'quasar'
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'LayoutDefault',

  components: {
    HelloWorld
  },

  data () {
    return {
      leftDrawerOpen: false,
      text:'',
      dense : true,
    }
  },
  methods: {
    cekToken(){
      // fetch(this.$store.state.url.URL_APP + "checkAuth", {
      //     method: "GET",
      //     headers: {
      //       "content-type": "application/json",
      //       authorization: "kikensbatara " + localStorage.token
      //     }
      // })
      //     .then(res => res.json())
      //     .then(res_data => {
      //         if (res_data.message == 'Tidak ter-Authorisasi') {
      //           localStorage.removeItem("token");
      //           localStorage.removeItem("profile");
      //           this.$router.push("/login");
      //         } 
      // });
    },

    refreshPage(){
      location.reload();
    },
    LogOut(){
      localStorage.removeItem("token");
      localStorage.removeItem("profile");
      this.$router.push("/login");
    },

  },
  computed: {
      checkLogin() {
          if (this.$route.name === 'login') {
              return true;
          } else {
              return false;
          }
      },
      
  },

  mounted () {
    this.cekToken();
  },
}
