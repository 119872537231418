
import UMUM from "../../library/umum";
import FETCHING from "../../library/fetching";

    export default {
        props: ["form", "uraian"],

        data() {
            return {
                // paths: [ {lat:-4.058654, lng: 122.515030}, {lat:-4.056354, lng: 122.516426}, {lat: -4.055688, lng: 122.514302}, {lat: -4.058533, lng: 122.512056} ],
                paths: [{lat:-4.058654, lng: 122.515030}],
                list_koordinat : [{lat:-4.058654, lng: 122.515030}],
                mdl_view : false,
                mdl_add_koordinat : false,

                UMUM : UMUM,
                FETCHING : FETCHING,
     
            }
        },


        methods: {

            getViewCoordinate : async function(){
                // this.$store.commit("shoWLoading");
                var body = {
                    form : this.form,
                }
                var data = await FETCHING.FETCH_POST(this.$store.state.url.URL_koordinatTanah + "view", body)
                // console.log(data);


                
                if (data.length <= 0) {
                    this.list_koordinat = [{lat:-4.058654, lng: 122.515030}]
                    this.paths = [{lat:-4.058654, lng: 122.515030}]
                    
                } else {

                    var arr = []
                    data.forEach(element => {
                        arr.push({
                            lat: parseFloat(element.lat), 
                            lng: parseFloat(element.lng), 
                        })
                    });

                    this.paths = arr;
                    this.list_koordinat = arr
                }

                // this.$store.commit("hideLoading");

            },


            addCoordinate : function(){
                fetch(this.$store.state.url.URL_koordinatTanah + "addData", {
                    method: "POST",
                    headers: {
                        "content-type": "application/json",
                        authorization: "kikensbatara " + localStorage.token
                    },
                    body: JSON.stringify({
                        form : this.form,
                        list_koordinat : this.list_koordinat,
                    })
                }).then(res_data => {
                    this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
                    this.getViewCoordinate();
                });
            },

            addFieldCoordinate : function(){
                this.list_koordinat.push({
                    lat : 0,
                    lng : 0,
                })
            },
            removeFieldCoordinate : function(index){
                this.list_koordinat.splice(index, 1);
            },

            Notify : function(message, positive, icon){
                this.$q.notify({
                    message: message,
                    color: positive,
                    icon: icon,
                    position : 'top',
                    timeout: 500,
                })
            },
        },



        mounted () {
            this.getViewCoordinate();
        },
        
    }
