import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


import  store  from '../store/index'

// console.log(store.state)


Vue.use(VueRouter);





function loggedInRedirectDashboard(to, from, next) {
  if (localStorage.token) {
    next('/');
  } else {
    next();
  }
}

function isLoggedIn(to, from, next) {
  if (localStorage.token) {
    next();
  } else {
    next('/login');
  }
}


  const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/login.vue'),
    beforeEnter: loggedInRedirectDashboard,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: isLoggedIn,
  },
 
  {
    path: '/routerAdd',
    name: 'routerAdd',
    component: () => import('../views/auth/routerAdd.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/levelAuth',
    name: 'levelAuth',
    component: () => import('../views/auth/levelAuth.vue'),
    beforeEnter: isLoggedIn,
  },

 
  {
    path: '/masterLevel',
    name: 'masterLevel',
    component: () => import('../views/dataMaster/masterLevel.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/registrasi',
    name: 'registrasi',
    component: () => import('../views/dataMaster/registrasi.vue'),
    beforeEnter: isLoggedIn,
  },




  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/register',
    name: 'register',
    component: () => import('../views/auth/register.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/m_asal_usul',
    name: 'm_asal_usul',
    component: () => import('../views/dataMaster/m_asal_usul.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/m_jenis_barang',
    name: 'm_jenis_barang',
    component: () => import('../views/dataMaster/m_jenis_barang.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/m_unit_kerja',
    name: 'm_unit_kerja',
    component: () => import('../views/dataMaster/m_unit_kerja.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/m_satuan',
    name: 'm_satuan',
    component: () => import('../views/dataMaster/m_satuan.vue'),
    beforeEnter: isLoggedIn,
  },







  {
    path: '/kiba',
    name: 'kiba',
    component: () => import('../views/kib/kiba.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/pengadaanTanah',
    name: 'pengadaanTanah',
    component: () => import('../views/tanah/pengadaanTanah.vue'),
    beforeEnter: isLoggedIn,
    },
  {
    path: '/ganti_rugi',
    name: 'ganti_rugi',
    component: () => import('../views/tanah/ganti_rugi.vue'),
    beforeEnter: isLoggedIn,
  },
  
  {
    path: '/sengketa',
    name: 'sengketa',
    component: () => import('../views/sengketa/sengketa.vue'),
    beforeEnter: isLoggedIn,
  },
  
  {
    path: '/petaTanah',
    name: 'petaTanah',
    component: () => import('../views/petaTanah/petaTanah.vue'),
    beforeEnter: isLoggedIn,
  },



]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
