import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './quasar'

import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);


import VueRandomColor from 'vue-randomcolor'
Vue.use(VueRandomColor)


// import Dropdown from 'vue-simple-search-dropdown';
// Vue.use(Dropdown);

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBpfmnRNSves2ctFvLihU_9-t4_iRn-QeY',
    // key: 'AIzaSyDj5SE_VrndIUWcKKO_n-j6IN04dRHy4pk', dari fajar
    v: '3.26',
    libraries: 'places, drawing, visualization',  // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  autobindAllEvents: true,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

  // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)


import sideBar from './components/sideBar.vue'
Vue.component('SideBar', sideBar);

import topBar from './components/topBar.vue'
Vue.component('topBar', topBar);

import ProgressLoading from './components/ProgressLoading.vue'
Vue.component('ProgressLoading', ProgressLoading);

import pdfViewKu from './components/pdfView.vue'
Vue.component('pdfViewKu', pdfViewKu);

import detileTanah from './components/detileTanah.vue'
Vue.component('detileTanah', detileTanah);

import coordinateMap from './components/templateCoordinate/coordinateMap.vue'
Vue.component('coordinateMap', coordinateMap);

import GmapCluster from 'vue2-google-maps/dist/components/cluster' // replace src with dist if you have Babel issues
Vue.component('GmapCluster', GmapCluster)



import Print from 'vue-print-nb'
Vue.use(Print);




// const socket = io('http://socketserver.com:5010');


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
