import Vue from 'vue'
import Vuex from 'vuex'

import { Loading, QSpinnerFacebook, } from 'quasar'
import { Notify } from 'quasar'

// var URL = 'http://localhost:5037/';
var URL = 'https://server.sita.konaweselatankab.go.id/';

// 192.168.1.126:8081

// import Menu from '../library/menu'

Vue.use(Vuex)



const store1 = {
  state: {
    KAT: 'Desa', //(Desa, Kelurahan)

    KECAMATAN: '',
    DES_KEL: '',
    // menu : Menu.menu,
    coordinat: {
      lat: -4.034694,
      lng: 122.484263
    },
    btn: {
      add: false,
      edit: false,
      remove: false,
    },
    url: {
      URL_APP: URL,
      URL_DASHBOARD: URL + 'api/v1/dashboard/',
      URL_MENU_LIST: URL + 'api/v1/menuList/',
      URL_LEVEL_USERS: URL + 'api/v1/levelUsers/',
      URL_REGISTRASI: URL + 'api/v1/pengaturan_registrasi/',


      URL_MASTER_ASAL_USUL: URL + 'api/v1/m_asal_usul/',
      URL_MASTER_JENIS_BARANG: URL + 'api/v1/m_jenis_barang/',

      URL_MASTER_KECAMATAN: URL + 'api/v1/m_kecamatan/',
      URL_MASTER_DES_KEL: URL + 'api/v1/m_des_kel/',
      URL_MASTER_UNIT_KERJA: URL + 'api/v1/m_unit_kerja/',
      URL_MASTER_SUB_UNIT_KERJA: URL + 'api/v1/m_sub_unit_kerja/',
      URL_MASTER_TAHUN: URL + 'api/v1/m_tahun/',
      URL_MASTER_SATUAN: URL + 'api/v1/m_satuan/',

      URL_KIB_A: URL + 'api/v1/kiba/',
      URL_koordinatTanah: URL + 'api/v1/koordinatTanah/',
      URL_ganti_rugi: URL + 'api/v1/ganti_rugi/',
      URL_ganti_rugi_penerima: URL + 'api/v1/ganti_rugi_penerima/',
      URL_sengketa_tanah: URL + 'api/v1/sengketa_tanah/',

      // NEW
      MAPS_KIBA: URL + 'api/v1/maps_kiba/',

    },

    aksesWilayah: [
      { id: 0, uraian: 'Satu Desa' },
      { id: 1, uraian: 'Satu Kecamatan' },
      { id: 2, uraian: 'Satu Kabupaten' },
    ],


    kamio: 'cccccc',
    tahun: [2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
    sumberKOps: ['Hibah', 'Pengadaan BUMDes', 'Lainnya'],

    STATUS_SOSIAL: [
      { id: 'K', uraian: 'Kawin' },
      { id: 'BK', uraian: 'Belum Kawin' },
      { id: 'J', uraian: 'Janda' },
      { id: 'D', uraian: 'Duda' }
    ],

    STATUS_TPT_USAHA: [
      { id: 'MS', uraian: 'Milik Sendiri' },
      { id: 'K', uraian: 'Kontrak' },
      { id: 'S', uraian: 'Sewa' },
      { id: 'L', uraian: 'Lainnya' }
    ],

    


    listMenu: [],
    listAsalUsul: [],
    listJenisBarang: [],
    listSubUnitKerja: [],
    listSatuan : [],

    listTahun: [],
    listKecamatan: [],
    listDesKel: [],


    listKibA : [],








    cek_load_data: true,

    max_rekrut: 30,
    min_persentase: 39.5,


  },
  mutations: {

    getStorage(state) {
      var get_profile = JSON.parse(localStorage.profile);
      state.unit_kerja = get_profile.profile.unit_kerja;
    },
    shoWLoading() {
      const spinner = typeof QSpinnerFacebook !== 'undefined'
        ? QSpinnerFacebook // Non-UMD, imported above
        : Quasar.components.QSpinnerFacebook // eslint-disable-line


      Loading.show({
        spinner,
        spinnerColor: 'yellow',
        spinnerSize: 140,
        backgroundColor: 'purple',
      })
    },
    hideLoading() {
      Loading.hide()
    },
    shoWNotify(state, message, color, icon) {
      Notify.create({
        message: message,
        color: color,
        position: 'top-right',
        icon: icon
      })
    },

    getProv(state) {
      fetch(state.url.URL_DATAMASTER_WILAYAH + "list", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          cari_value: ''
        })
      })
        .then(res => res.json())
        .then(res_data => {
          state.listProv = res_data
        });
    },


    addEditor() {
      setTimeout(() => {


        new FroalaEditor('textarea#editor', {
          placeholderText: 'Mulai menuliskan sesuatu..',
          height: 350,
          zIndex: 8000,
          tabSpaces: 4,
          indentMargin: 10,
          attribution: false,
          imageUpload: true,
          imageDefaultAlign: 'left',
          // imageDefaultDisplay: 'inline-block',
          imageAllowedTypes: ['jpeg', 'jpg', 'png'],
          imageMaxSize: 5 * 1024 * 1024,
          tableStyles: {
            class1: 'Alternate Rows',
            class2: 'Dashed Borders',
            class3: 'No Border'
          },
          // imageManagerLoadURL: "http://localhost:5010/images/",
          tableColors: [
            '#61BD6D', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC',
            '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#000000',
            '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#FFFFFF',
            '#FAC51C', '#F37934', '#D14841', '#B8312F', '#7C706B', '#D1D5D8', 'REMOVE'
          ],
          events: {
            "image.beforeUpload": function (files) {
              var editor = this;
              // console.log('image di pangggil')

              if (files.length) {
                var reader = new FileReader();
                reader.onload = function (e) {
                  var result = e.target.result;
                  editor.image.insert(result, null, null, editor.image.get());
                };
                reader.readAsDataURL(files[0]);
              }
              editor.popups.hideAll();


              // var formData = new FormData();

              // formData.append("file", files[0]);
              // fetch(this.$store.state.url.URL_ADM_CAPAIAN_KEGIATAN + "addFoto", {
              //   method: "POST",
              //   headers: {
              //     // "content-type": "application/json",
              //     authorization: "kikensbatara " + localStorage.token
              //   },
              //   body: formData
              // }).then(res_data => {

              // });












              return false;
            }
          }






        })
      }, 50);

    },



  },
  actions: {
  },
  modules: {
  }
}

const DataStore = new Vuex.Store(store1);
export default DataStore;



