

import MENU from  '../library/menu'


export default {
  data() {
    return {
      leftDrawerOpen: true,
      // menu : MENU,
    };
  },

  methods: {

   getMENU(){
     MENU.getMenu();
   },




    logout: function() {
      localStorage.removeItem("token");
      localStorage.removeItem("profile");
      this.$router.push("/login");
    },
    checkLength(data){
      if(data.length <= 0){
        return false
      }else{
        return true
      }
    },

    checkLength2(data){
      var hidex = false

      data.forEach(element => {
        if (element.submenu.length > 0 || element.type == 'single') {
          hidex = true
        } 
      });

     

      return hidex


    },

    routex(route){
      window.open(
      route,
      '_blank' // <- This is what makes it open in a new window.
    );

    },






  },

  mounted () {
    this.getMENU()
  },
};
