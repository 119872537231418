


import CHART from '../library/chart'
import WILAYAH from  '../library/getWilayah'
import UMUM from  '../library/umum.js'

export default {
  data() {
    return {
      alert: false,
      simpan1: false,

      cek_load_data : true,
    }
  },
  methods: {


  
    JenisBarang : async function(){
      // var body = this.form 
      var body = {data : ''}
      var dataArray = await CHART.postData('JenisBarang', body);
 
      await CHART.chartPie('JenisBarang', 'Luas Berdasarkan Jenis Barang (M2)', dataArray)
      this.cek_load_data = false
    },

    AsalUsul : async function(){
      // var body = this.form 
      var body = {data : ''}
      var dataArray = await CHART.postData('AsalUsul', body);
 
      await CHART.chartPie('AsalUsul', 'Luas Berdasarkan Jenis Barang (M2)', dataArray)
      this.cek_load_data = false
    },

  






  },

  mounted () {
   

      this.JenisBarang()
      this.AsalUsul();


  },
}
