import { render, staticRenderFns } from "./ProgressLoading.vue?vue&type=template&id=395adc46"
var script = {}
import style0 from "./ProgressLoading.vue?vue&type=style&index=0&id=395adc46&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QImg} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QImg})
