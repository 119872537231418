import { render, staticRenderFns } from "./coordinateMap.vue?vue&type=template&id=c0d35206&scoped=true"
import script from "./coordinateMap.vue?vue&type=script&lang=js"
export * from "./coordinateMap.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0d35206",
  null
  
)

export default component.exports
import {QBtn,QDialog,QCard,QCardSection,QCardActions,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QDialog,QCard,QCardSection,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
