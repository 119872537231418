
import UMUM from "../library/umum";
    export default {
        props: ["form", 'uraian'],
        data() {
            return {
                UMUM: UMUM
            }
        },
        methods: {
            name() {
                
            }
        },
        mounted () {
            
        },
        
    }
